import './src/scss/theme.scss'
import('../../src/js/app')
import lightGallery from 'lightgallery'
import lgVideo from 'lightgallery/plugins/video'
require('owl.carousel')

$(document).ready(function () {
  lightGallery(document.getElementById('gallery-container'), {
    plugins: [lgVideo]
  })

  // Owl carousel
  $('.owl-carousel').owlCarousel({
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: 6000
  })
})
